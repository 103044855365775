import React from "react";
import { useHistory } from "react-router-dom";

import mockStudentPic from "../assets/mockStudentPic.png";
import mockStudentPicBig from "../assets/mockStudentPicBig.png";
import api from "../api";
import { useLang } from "./lang";
import { useSearch } from "./search";
import { useAuth } from "./auth";

const MajorsContext = React.createContext();

const MajorsProvider = (props) => {
  const { t } = useLang();
  const { search } = useSearch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const { user } = useAuth();

  const initForm = {
    majorName: "",
    majorSpecialization: "",
    priceEuro: "",
    priceNonEuro: "",
    mode: "fulltime",
    language: "PL",
    degree: "bachelor",
    link: "",
  };

  const [form, setForm] = React.useState(initForm);

  const resetForm = () => setForm(initForm);

  const [data, setData] = React.useState({
    languageOptions: ["EN", "PL"],
    modeOptions: ["fulltime", "parttime"],
    degreeOptions: ["master", "bachelor"],
    majorOptions: [],
    items: [],
  });
  const [filters, setFilters] = React.useState({
    language: [],
    mode: [],
    degree: [],
    majorName: [],
  });
  const [sortBy, setSortBy] = React.useState("salary");
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const loadMajors = async () => {
    setLoading(true);
    const { data } = await api.get("universities/majors/");
    setData((prev) => ({
      ...prev,
      majorOptions: data.reduce(
        (acc, { major_name }) =>
          acc.includes(major_name) ? acc : [...acc, major_name],
        []
      ),
      items: data.map((item) => ({
        ...item,
        id: item.id,
        majorName: item.major_name,
        majorSpecialization: item.specialization,
        language: item.language,
        mode: item.mode,
        degree: item.degree,
        priceEuro: item.tuition,
        priceNonEuro: item.price_non_europe,
        link: item.link,
        clicks: item.clicks,
      })),
    }));
    setLoading(false);
  };

  const updateMajor = async (major) => {
    await api.post("universities/majors/", {
      id: major.id,
      major_name: major.majorName,
      specialization: major.majorSpecialization,
      tuition: major.priceEuro,
      price_non_europe: major.priceNonEuro,
      mode: major.mode,
      language: major.language,
      degree: major.degree,
      link: major.link,
      university_id: user.universityId,
    });
    history.push("majors");
  };

  return (
    <MajorsContext.Provider
      value={{
        data: {
          ...data,
          items: data.items
            .filter((item) =>
              Object.entries(filters)
                .map(([key, value]) => {
                  if (value.length == 0) {
                    return true; //empty filter, everything passes
                  } else {
                    return value.every((filter) => item[key]?.includes(filter));
                  }
                })
                .every((v) => v)
            )
            .filter((item) => {
              if (!search) {
                return true;
              } else {
                return `${item.majorName} ${t(item.majorSpecialization)}`
                  .toLowerCase()
                  .includes(search.toLowerCase());
              }
            }),
        },
        sortBy,
        setSortBy,
        filters,
        setFilters,
        loadMajors,
        updateMajor,
        form,
        initForm,
        setForm,
        resetForm,
        search,
        loading,
      }}
      {...props}
    />
  );
};

export const useMajors = () => React.useContext(MajorsContext);

export default MajorsProvider;
