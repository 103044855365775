import React from "react";

const LangContext = React.createContext();

const LangProvider = ({ translation, ...props }) => {
  const storLang = localStorage.getItem("userLanguage") || "pl";
  const [lang, setLanguage] = React.useState(
    storLang && storLang !== "null" && translation[storLang] ? storLang : "pl"
  );

  const setLang = (lang) => {
    setLanguage(lang);
    localStorage.setItem("userLanguage", lang);
  };

  const t = (key) => {
    return key
      ?.split(",")
      .map((k) => translation[lang][k] || k)
      .join(",");
  };

  const getAvailibleLangs = () =>
    Object.keys(translation).map((langCode) => ({
      code: langCode,
      title: translation[langCode].languageName,
      flagUrl: translation[langCode].flagUrl,
    }));

  return (
    <LangContext.Provider
      value={{
        getAvailibleLangs,
        lang,
        setLang,
        t,
      }}
      {...props}
    />
  );
};

export const useLang = () => React.useContext(LangContext);

export default LangProvider;
