import React from "react";
import { useLang } from "../context/lang";
import Dropdown from "react-bootstrap/Dropdown";
import { TriangleDownIcon } from "@primer/octicons-react";
import { ReactComponent as Globe } from "../assets/globe.svg";

export default ({ hideIcon = false, light = false }) => {
  const { t, lang, setLang, getAvailibleLangs } = useLang();

  return (
    <div className="d-flex w-100 align-items-center">
      {!hideIcon && <Globe className="ml-32 my-xl-16 my-8 mr-16" />}
      <Dropdown className={`${light ? "m-0" : "mx-4"}`}>
        <Dropdown.Toggle
          variant="outline-light"
          className={`${
            light
              ? "bg-white hover-blue"
              : "bg-gray-800 hover-bg-gray-700 border-0"
          }   no-after flex-center-between`}
        >
          <span>
            <span className={`${light ? "text-dark" : "text-white-50"} mr-16`}>
              {lang.toUpperCase()}
            </span>
          </span>
          <span className={`${light ? "text-dark" : "text-white-50"} d-flex`}>
            <TriangleDownIcon />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {getAvailibleLangs().map((option, key) => (
            <Dropdown.Item key={key} onClick={() => setLang(option.code)}>
              {option.code.toUpperCase()}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
