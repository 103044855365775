import React from "react";
import Popover from "react-bootstrap/Popover";

import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";

export default React.forwardRef((props, ref) => {
  const { notifications } = useAuth();
  const { t } = useLang();

  return (
    <Popover
      {...props}
      id="popover-notifications"
      className={`${props.className} size-notifications mw-100 bg-dark shadow rounded py-0 border-0`}
      arrowProps={{ style: { display: "none" } }}
      ref={ref}
      data-trigger="focus"
    >
      <div className="size-notifications position-relative overflow-y-scroll rounded">
        {notifications.length === 0 ? (
          <span className="text-white-50 m-10 top-0 position-absolute">
            {t("noNotifications")}
          </span>
        ) : (
          <div className="hover-underline position-fixed z-index-modal pt-10 pr-16 top-0 right-0">
            <span className="font-weight-bold text-white-70 cursor-pointer">
              {t("markAsRead")}
            </span>
          </div>
        )}

        {notifications.map((group, index) => (
          <>
            <div className="w-100 px-16 bg-dark position-sticky top-0 py-8 d-flex">
              <span className="text-white-50">{group[0].time}</span>
            </div>

            {group.map(notification => (
              <div className="d-flex w-100 px-16 py-8 hover-bg-gray-700 cursor-pointer">
                <img
                  className="rounded-circle border border-light"
                  src={notification.avatarUrl}
                />
                <div className="ml-10">
                  <div className="text-white-70">{`${notification.name} ${t(
                    "waitingForFeedback"
                  )}`}</div>
                  <div className="text-white-50">{notification.time}</div>
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
    </Popover>
  );
});
