import React from "react";

import Filter from "./Filter";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as ArrowSwitch } from "../assets/arrow-switch.svg";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";

import { useLang } from "../context/lang";
import { useStudents } from "../context/students";
export default () => {
  const { t } = useLang();
  const {
    data: {
      degreeOptions,
      statusOptions,
      languageOptions,
      modeOptions,
      dominantPersonalityOptions,
      items,
    },
    sortBy,
    setSortBy,
    exportStudents,
    filters,
    setFilters,
  } = useStudents();

  const handleSetFilter = (option, key) =>
    setFilters((prev) => ({
      ...prev,
      [key]: prev[key].includes(option) ? prev[key] : [...prev[key], option],
    }));

  const handleResetFilters = () =>
    setFilters((prev) =>
      Object.keys(prev).reduce((acc, value) => ({ ...acc, [value]: [] }), {})
    );

  const handleResetFilter = (filter, i) =>
    setFilters((prev) => ({
      ...prev,
      [filter]: prev[filter].filter((_, indx) => indx !== i),
    }));

 

  return (
    <>
      <div className="flex-center-between mt-32 justify-content-between">
        {/* <div className="flex-center-between">
          <Filter
            label={t("sortBy")}
            value={sortBy}
            options={sortByOptions}
            onChoose={option => setSortBy(option)}
          />
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-reverse-right">{t("reverse")}</Tooltip>
            }
          >
            <ArrowSwitch className="rotate-90 m-16 hover-elevate-200 cursor-pointer" />
          </OverlayTrigger>
        </div> */}
        <div className="flex-center-between flex-wrap">
          <Filter
            label={t("status")}
            options={statusOptions}
            onChoose={(option) => handleSetFilter(option, "status")}
          />
          <Filter
            label={t("language")}
            options={languageOptions}
            onChoose={(option) => handleSetFilter(option, "languages")}
          />
          <Filter
            label={t("mode")}
            options={modeOptions}
            onChoose={(option) => handleSetFilter(option, "mode")}
          />
          <Filter
            label={t("degree")}
            options={degreeOptions}
            onChoose={(option) => handleSetFilter(option, "degree")}
          />
          {/* <Filter
            label={t("dominantPersonality")}
            options={dominantPersonalityOptions}
            onChoose={option => handleSetFilter(option, "dominantPersonality")}
          /> */}
        </div>
      </div>
      {Object.values(filters).some((v) => Boolean(v.length)) && (
        <>
          <div className="d-flex w-100 mt-32">
            <h5 className="hover-underline">
              {t("activeFilters")}
              <span
                className="text-danger text-14 font-weight-normal mb-8 ml-16 cursor-pointer"
                onClick={handleResetFilters}
              >
                {t("clearAll")}
              </span>
            </h5>
          </div>
          <div className="d-flex w-100 mt-4">
            {Object.entries(filters).map(([filter, value]) =>
              value.map((v, i) => (
                <div
                  key={i}
                  className="bg-gray-300 rounded px-10 py-8 mr-8 flex-center-between"
                >
                  <span className="text-14">{t(v)}</span>
                  <PlusIcon
                    className="rotate-45 ml-8 hover-elevate-400 cursor-pointer"
                    onClick={() => handleResetFilter(filter, i)}
                  />
                </div>
              ))
            )}
          </div>
        </>
      )}
    </>
  );
};
