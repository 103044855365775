import React from "react";
import { useHistory } from "react-router-dom";
import manAvatar from "../assets/man.png";

import api from "../api";
import { useLang } from "./lang";
import { useModal } from "./modal";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [user, setUser] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    university: "",
    universityId: "",
    universityExternalId: "",
    avatarUrl: "",
  });

  const [notifications, setNotifications] = React.useState([
    // [
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ]
  ]);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [authError, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { t } = useLang();
  const { openModal } = useModal();
  const history = useHistory();

  const isAuthenticated = Boolean(token);

  React.useEffect(() => {
    if (isAuthenticated) {
      getProfile();
    }
  }, []);

  const getProfile = async () => {
    try {
      const { data } = await api.get("universities/profile/");
      setProfile(data);
    } catch (err) {
      console.error(err.response);
    }
  };

  const setProfile = (data) => {
    setUser({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      university: data.university?.name,
      universityId: data.university?.id,
      universityExternalId: data.university.external_id,
      avatarUrl: manAvatar, //TODO
    });
  };

  const login = async (username, password) => {
    try {
      const {
        data: { token },
      } = await api.post("universities/login/", { username, password });
      console.log(token);
      localStorage.setItem("token", token);
      api.defaults.headers.common["Authorization"] = ` Token ${token}`;
      setToken(token);
      getProfile();
      history.push("/");
      window.location.reload(false);
    } catch (e) {
      console.error(e);
      console.log(e.response);
      if (e.response.status === 401) {
        setErrorMessage(t("wrongCredentials"));
      } else {
        setErrorMessage(
          e.response?.data.non_field_errors[0] || t("defaultErrorMessage")
        );
      }
    }
  };

  const sendPasswordRecoverLink = () => {
    openModal(t("checkYourEmail"), t("emailRecoveryMessage"));
  };

  const logout = async () => {
    setUser({
      id: "",
      firstName: "",
      lastName: "",
      firms: [],
      avatarUrl: "",
    });
    history.push("/login");
    localStorage.clear();
  };

  const updateProfile = async (user) => {
    console.log(user);
    await api.put("universities/profile/", user);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        loading,
        notifications,
        authError,
        user,
        sendPasswordRecoverLink,
        updateProfile,
      }}
      {...props}
    />
  );
}

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
