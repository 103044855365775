import React from "react";

import malePic from "../assets/userpicMale.svg";
import femalePic from "../assets/userpicFemale.svg";

import api from "../api";
import _ from "lodash";
import { useLang } from "./lang";
import { useSearch } from "./search";

const StudentsContext = React.createContext();

const StudentsProvider = (props) => {
  const { t } = useLang();
  const { search } = useSearch();

  const [loading, setLoading] = React.useState(false);

  const nextPageRef = React.useRef();

  const [data, setData] = React.useState({
    // sortByOptions: ["salary", "name"],
    statusOptions: [
      "new",
      "contacted",
      "applied",
      "accepted",
      "inProgress",
      "notInterested",
      "student",
    ],
    languageOptions: ["EN", "PL"],
    modeOptions: ["fulltime", "parttime", "online"],
    degreeOptions: ["master", "bachelor", "postgrad"],
    dominantPersonalityOptions: [
      "r_score",
      "i_score",
      "a_score",
      "s_score",
      "e_score",
      "c_score",
    ],
    items: [],
  });
  const [filters, setFilters] = React.useState({
    status: [],
    languages: [],
    mode: [],
    degree: [],
    dominantPersonality: [],
  });
  const [sortBy, setSortBy] = React.useState("salary");

  const [student, setStudent] = React.useState(null);

  const [rejectTemplateOptions, setRejectTemplateOptions] = React.useState([
    { key: 0, value: "test", message: "testmessage" },
    { key: 1, value: "test2", message: "testmessage2" },
  ]);

  const loadStudent = async (id) => {
    const { data } = await api.get(`universities/students/${id}/`);
    setStudent({
      id: data.id,
      name: data.name,
      email: data.email,
      status: data.is_already_student ? "student" : data.status,
      sex: data.sex,
      widgetLanguage: data.widget_language,
      avatarSrc: data.sex === "male" ? malePic : femalePic,
      citizenship: data.citizenship,
      city: data.city,
      mode: data.mode,
      language: data.languages,
      degree: data.degree,
      skills: Object.keys(data)
        .filter((k) => k.includes("_score"))
        .map((key) => ({ key, value: data[key] })),
      maxScore: Math.max(
        ...Object.values(_.pickBy(data, (v, k) => k.includes("_score")))
      ),
      dominantPersonality: Object.keys(data).reduce((a, b) =>
        data[a] > data[b] ? a : b
      ),
      majors: data.majors.map(
        ({ major_name, specialization, mode, language, fit, link }) => ({
          major_name,
          specialization,
          link,
          mode,
          language,
          fit,
        })
      ),
      submitDate: data.submit_date,
    });
  };

  const parseStudent = (item) => ({
    ...item,
    status: item.is_already_student ? "student" : item.status,
    salary: "TODO:money",
    name: item.name || "noname",
    age: "TODO:age",
    id: item.id,
    avatarSrc: item.sex === "male" ? malePic : femalePic,
    school: "TODO:wybrany kierunek",
    major: "TODO:wybrany kierunek",
    skills: Object.keys(item)
      .filter((k) => k.includes("_score"))
      .map((key) => ({ key, value: item[key] })),
    dominantPersonality: Object.keys(
      _.pickBy(item, (v, k) => k.includes("_score"))
    ).reduce((a, b) => (item[a] > item[b] ? a : b)),
    mode: item.mode, // todo
    submitDate: item.submit_date,
  });

  const loadStudents = async () => {
    setLoading(true);

    const {
      data: { results, next },
    } = await api.get("universities/students/");
    nextPageRef.current = next;
    setData((prev) => ({
      ...prev,
      items: results.map(parseStudent),
    }));
    setLoading(false);
  };

  const loadMoreStudents = async () => {
    if (nextPageRef.current) {
      const {
        data: { results, next },
      } = await api.get(
        `universities/students/?page=${nextPageRef.current.split("=")[1]}`
      );
      nextPageRef.current = next;
      const parsedResult = results.map(parseStudent);

      setData((prev) => ({
        ...prev,
        items: [...prev.items, ...parsedResult],
      }));
    }
  };

  const updateStudent = async (id, newData) => {
    await api.put(`universities/students/update/${id}/`, newData);
  };

  const deleteStudent = async (id) => {
    await api.delete(`universities/students/${id}/`);
  };

  const applyFiltersAndSearch = (items) =>
    items
      .filter((item) =>
        Object.entries(filters)
          .map(([key, value]) => {
            if (value.length == 0) {
              return true; //empty filter, everything passes
            } else {
              return value.every((filter) => item[key]?.includes(filter));
            }
          })
          .every((v) => v)
      )
      .filter((item) => {
        if (!search) {
          return true;
        } else {
          return `${t(item.name)} ${t(item.mode)} ${t(item.degree)}`
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      });

  return (
    <StudentsContext.Provider
      value={{
        data: {
          ...data,
          items: applyFiltersAndSearch(data.items),
        },
        loading,
        sortBy,
        setSortBy,
        filters,
        setFilters,
        loadStudent,
        loadStudents,
        hasMore: Boolean(nextPageRef.current),
        nextPageRef,
        loadMoreStudents,
        updateStudent,
        student,
        rejectTemplateOptions,
        deleteStudent,
        search,
      }}
      {...props}
    />
  );
};

export const useStudents = () => React.useContext(StudentsContext);

export default StudentsProvider;
