import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import { useLang } from "../context/lang";

export default ({ value, chooseOptions, onChoose }) => {
  const { t } = useLang();

  const CustomToggle = React.forwardRef(({ children, onClick, color }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      className={`text-white text-14 bg-${color} my-4 rounded-pill px-8 cursor-pointer`}
    >
      {children}
      <span className="ml-2">&#x25bc;</span>
    </div>
  ));

  const statusPill = (color, label) =>
    chooseOptions ? (
      <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          color={color}
          id="dropdown-custom-components"
        >
          <span>{label}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {chooseOptions.map(option => (
            <Dropdown.Item onClick={() => onChoose(option)}>
              {t(option)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <div className={`text-white text-14 bg-${color} my-4 rounded-pill px-8`}>
        <span>{label}</span>
      </div>
    );

  switch (value) {
    case "new":
      return statusPill("purple", t("new"));
    case "contacted":
      return statusPill("teal", t("contacted"));
    case "applied":
      return statusPill("red", t("applied"));
    case "accepted":
      return statusPill("yellow", t("accepted"));
    case "inProgress":
      return statusPill("bronse", t("inProgress"));
    case "notInterested":
      return statusPill("teal", t("notInterested"));
    case "student":
      return statusPill("blue", t("student"));
  }
  return null;
};
