import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { TriangleDownIcon } from "@primer/octicons-react";

import { QuestionIcon } from "@primer/octicons-react";
import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";
import FormInput from "../components/FormInput";
import api from "../api";

export default () => {
  const { t, lang, setLang, getAvailibleLangs } = useLang();
  const { sendPasswordRecoverLink, user, updateProfile, logout } = useAuth();
  const [passwordError, setPasswordError] = React.useState("");

  const [form, setForm] = React.useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  React.useEffect(() => {
    setForm({ ...user });
  }, [user]);

  const handleSubmitPersonalInfo = (e) => {
    console.log(e);
    e.preventDefault();
    updateProfile({
      first_name: document.getElementById("firstName").value,
      last_name: document.getElementById("lastName").value,
    });
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    const oldPassword = document.getElementById("oldPassword").value;
    const newPassword = document.getElementById("newPassword").value;
    const repeatNewPassword =
      document.getElementById("repeatNewPassword").value;

    if (newPassword !== repeatNewPassword) {
      setPasswordError(t("differentPasswords"));
      return;
    }

    try {
      await api.post("universities/profile/change_password/", {
        old_password: oldPassword,
        new_password: newPassword,
      });
      logout();
    } catch (e) {
      console.log(e.response);
      if (e.response.status === 400) {
        setPasswordError(t(e.response.data));
      } else {
        setPasswordError(t("defaultErrorMessage"));
      }
    }
  };

  return (
    <>
      <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm">
        <Form onSubmit={handleSubmitPersonalInfo} className="d-flex flex-wrap">
          <div className="text-18 mb-24 font-weight-bold w-100">
            {t("personalInfo")}
          </div>
          <FormInput
            id="firstName"
            label={t("firstName")}
            value={form.firstName}
            onChange={(e) =>
              setForm((p) => ({ ...p, firstName: e.target.value }))
            }
            required
            width="50%"
            className="pr-8"
          />
          <FormInput
            id="lastName"
            value={form.lastName}
            onChange={(e) =>
              setForm((p) => ({ ...p, lastName: e.target.value }))
            }
            label={t("lastName")}
            required
            width="50%"
          />
          <Button
            className="rounded-lg mt-16 mb-16"
            variant="primary"
            type="submit"
          >
            {t("saveChanges")}
          </Button>
        </Form>
      </div>
      <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm mt-24">
        <Form onSubmit={handleSubmitNewPassword} className="d-flex flex-wrap">
          <div className="text-18 mb-24 font-weight-bold w-100">
            {t("changePassword")}
          </div>
          <FormInput
            id="oldPassword"
            label={t("oldPassword")}
            type="password"
            required
            width="100%"
            className="pr-8"
          />
          <FormInput
            id="newPassword"
            label={t("newPassword")}
            type="password"
            required
            width="50%"
            className="pr-8"
          />
          <FormInput
            id="repeatNewPassword"
            label={t("repeatNewPassword")}
            type="password"
            required
            width="50%"
            className="pr-8"
          />
          <div className="text-danger text-14 mb-8 w-100">{passwordError}</div>
          <Button
            className="rounded-lg mt-16 mb-16"
            variant="primary"
            type="submit"
          >
            {t("saveChanges")}
          </Button>
        </Form>
      </div>

      {/* <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm mt-24">
        <Form onSubmit={handleSubmitPasswordRecovery}>
          <div className="text-18 mb-10 font-weight-bold">
            {t("recoverPassword")}
          </div>
          <div className="text-muted text-16 mb-16">
            {t("recoverPasswordMessage")}
          </div>
          <FormInput label={t("yourEmail")} required />
          <Button
            className="rounded-lg mt-16 mb-16"
            variant="primary"
            type="submit"
          >
            {t("emailMeRecover")}
          </Button>
        </Form>
      </div> */}
      {/* <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm mt-24">
        <Form
          onSubmit={handleSubmitPasswordRecovery}
          className="d-flex flex-wrap"
        >
          <div className="flex-center-between w-100 align-items-start">
            <div className="text-18 mb-10 font-weight-bold">
              {t("autoNegative")}
            </div>
            <div className="cursor-pointer hover-elevate-200 text-muted d-flex">
              <QuestionIcon size={18} />
            </div>
          </div>

          <div className="text-muted text-16 mb-24">
            {t("autoNegativeMessage")}
          </div>
          <FormInput
            label={t("amountOfDays")}
            required
            width="50%"
            className="pr-8"
          />
          <FormInput label={t("amountOfMonth")} required width="50%" />
          <FormInput
            label={t("message")}
            as="textarea"
            controlProps={{ rows: 5 }}
            required
          />
          <Button
            className="rounded-lg mt-16 mb-16"
            variant="primary"
            type="submit"
          >
            {t("saveChanges")}
          </Button>
        </Form>
      </div>
      <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm mt-24">
        <Form
          onSubmit={handleSubmitPasswordRecovery}
          className="d-flex flex-wrap"
        >
          <div className="flex-center-between w-100 align-items-start">
            <div className="text-18 mb-10 font-weight-bold">
              {t("autoShortlist")}
            </div>
            <div className="cursor-pointer hover-elevate-200 text-muted d-flex">
              <QuestionIcon size={18} />
            </div>
          </div>
          <div className="text-muted text-16 mb-24">
            {t("autoShortlistMessage")}
          </div>
          <FormInput
            label={t("amountOfDays")}
            required
            width="50%"
            className="pr-8"
          />
          <FormInput label={t("amountOfMonth")} required width="50%" />
          <FormInput
            label={t("message")}
            as="textarea"
            controlProps={{ rows: 5 }}
            required
          />
          <Button
            className="rounded-lg mt-16 mb-16"
            variant="primary"
            type="submit"
          >
            {t("saveChanges")}
          </Button>
        </Form>
      </div> */}
    </>
  );
};
