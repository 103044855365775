import Axios from "axios";

import { API_BASE_URL } from "./config";

const api = Axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
      ? ` Token ${localStorage.getItem("token")}`
      : undefined,
  },
});

export default api;
