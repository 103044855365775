import React from "react";
import StudentsFilters from "../components/StudentsFilters";
import StudentCard from "../components/StudentCard";
import StudentsActions from "../components/StudentsActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-bootstrap/Fade";

import Export from "./Export";

import { ReactComponent as ExportIcon } from "../assets/export.svg";
import { Loader } from "../components/Loader";
import { useLang } from "../context/lang";
import { useStudents } from "../context/students";
import { useHistory } from "react-router-dom";
import { useSearch } from "../context/search";

export default () => {
  const { t } = useLang();
  const history = useHistory();
  const {
    data,
    loadStudents,
    loadMoreStudents,
    hasMore,
    nextPageRef,
    loading,
  } = useStudents();

  const { search } = useSearch();

  React.useEffect(() => {
    loadStudents();
  }, []);

  const [checkedStudents, setCheckedStudents] = React.useState([]);
  const [openExport, setOpenExport] = React.useState(false);

  const handleCheckStudent = (id) =>
    checkedStudents.includes(id)
      ? setCheckedStudents(checkedStudents.filter((v) => v !== id))
      : setCheckedStudents([...checkedStudents, id]);

  const handleChooseStudent = (id) => history.push(`/students/${id}`);

  /** Infinite scroll effect */
  React.useEffect(() => {
    if (window) {
      window.addEventListener("scroll", trackScrolling, true);
    }
    return () => {
      if (window) {
        window.removeEventListener("scroll", trackScrolling);
      }
    };
  }, []);

  const loadingMore = React.useRef(false);

  const trackScrolling = async (event) => {
    if (window.location.pathname == "/students") {
      if (
        document.body.offsetHeight - (window.innerHeight + window.scrollY) <
          500 &&
        nextPageRef.current
      ) {
        if (!loadingMore.current) {
          loadingMore.current = true;
          await loadMoreStudents();
          loadingMore.current = false;
          //fire once again to check if new items appeared and wasn't filtered by search
          trackScrolling();
        }
      }
      // else if (window.scrollY == 0 && !loadingMore.current && event) {
      //   if (nextPageRef.current?.split("=")[1] !== "2") {
      //     //it is not the first page
      //     loadingMore.current = true;
      //     await loadStudents();
      //     loadingMore.current = false;
      //   }
      // }
    }
  };

  React.useEffect(() => {
    handleLoadLessStudents();
    trackScrolling();
  }, [search]);

  const handleLoadLessStudents = async () => {
    if (nextPageRef.current?.split("=")[1] !== "2" && !loadingMore.current) {
      loadingMore.current = true;
      await loadStudents();
      loadingMore.current = false;
      trackScrolling();
    }
  };

  return (
    <Loader loading={loading}>
      <div className="w-100">
        <div className="w-100 flex-center-between">
          <h2>{t("allStudents")}</h2>
          <div />
          {!openExport && (
            <div
              className="cursor-pointer bg-transparent border-05 rounded border-gray-400 hover-blue no-after flex-center-between pr-8"
              onClick={() => setOpenExport(true)}
            >
              <ExportIcon className="m-4" />
              {t("Export")}
            </div>
          )}
        </div>
        {openExport && (
          <Fade in={openExport}>
            <Export onClose={() => setOpenExport(false)} />
          </Fade>
        )}
        <StudentsFilters />
        <StudentsActions
          items={checkedStudents}
          onClear={() => setCheckedStudents([])}
        />
        <Row>
          {data.items.map((item) => (
            <Col sm={12} md={6} lg={4} key={item.id} className="px-15 px-md-0">
              <StudentCard
                student={item}
                isChecked={checkedStudents.includes(item.id)}
                onCheck={() => handleCheckStudent(item.id)}
                onClick={() => handleChooseStudent(item.id)}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Loader loading={nextPageRef.current} />
        </Row>
      </div>
    </Loader>
  );
};
