import React from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

import variables from "../styles/app.scss"; //use export

export default ({ to, onClick, icon, text }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isActive = pathname.includes(to);
  return (
    <div
      onClick={onClick ? () => onClick() : () => history.push(to)}
      className={`w-100 d-flex cursor-pointer hover-bg-gray-700 ${
        isActive &&
        "bg-gray-700 text-16 border-0 border-left-3 border-left-mobile-0 border-gray-100"
      }`}
    >
      <div className="mx-32 my-xl-16 my-8 flex-center">
        {React.cloneElement(icon, {
          fill: isActive ? "#F7F8FC" : "#B0B2BA",
          ...icon.props,
        })}
      </div>
      <a
        className={`text-white-50 text-18 my-xl-16 my-8 ${
          isActive && "text-white-70"
        } ${!isActive && "text-gray-600-mobile"}`}
      >
        {text}
      </a>
    </div>
  );
};
