import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ChangeLanguage from "./ChangeLanguage";
import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";

import FormInput from "./FormInput";

export default () => {
  const { login, authError } = useAuth();
  const { t } = useLang();
  const handleSubmit = (e) => {
    e.preventDefault();
    login(
      document.getElementById("login").value,
      document.getElementById("password").value
    );
  };
  return (
    <Form
      onSubmit={handleSubmit}
      style={{ height: "100vh" }}
      className="flex-center w-100 flex-column"
    >
      <FormInput controlId="login" label={t("login")} width="200px" required />
      <FormInput
        controlId="password"
        label={t("password")}
        width="200px"
        required
        type="password"
      />
      {authError && <div className="text-danger text-14 mb-8">{authError}</div>}
      <div className="d-flex" style={{ width: "200px" }}>
        <ChangeLanguage hideIcon light />
        <Button variant="primary" type="submit" style={{ minWidth: "115px" }}>
          {t("signIn")}
        </Button>
      </div>
    </Form>
  );
};
