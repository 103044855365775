import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import { XIcon } from "@primer/octicons-react";

import { useLang } from "../context/lang";
import FormInput from "../components/FormInput";
import { useStudents } from "../context/students";
import { ReactComponent as ExportIcon } from "../assets/export.svg";
import { Loader } from "../components/Loader.js";
import { useExportStudents } from "../hooks/useExportStudents";

export default ({ onClose }) => {
  const { t } = useLang();

  const { exportStudents, exportProgress, stopExport } = useExportStudents();

  const [loadingExport, setLoadingExport] = React.useState(false);
  const [stoppingExport, setStoppingExport] = React.useState(false);

  const [form, setForm] = React.useState({
    fromDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    )
      .toISOString()
      .split("T")?.[0],
    toDate: new Date().toISOString().split("T")?.[0],
  });

  const convertToCSV = (arr) => {
    if (arr.length) {
      const array = [Object.keys(arr[0])].concat(arr);

      return array
        .map((it) => {
          return Object.values(it)
            .map((cell) => {
              return String(cell).replaceAll(", ", " ").replaceAll(",", " ");
            })
            .toString();
        })
        .join("\n");
    } else {
      return [];
    }
  };

  const handleExport = async () => {
    setLoadingExport(true);
    try {
      const data = await exportStudents({ ...form });
      var blob = new Blob([convertToCSV(data)], {
        type: "text/csv;charset=utf-8;",
      });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(
          blob,
          `asessments_${form.fromDate}-${form.toDate}.csv`
        );
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute(
            "download",
            `asessments_${form.fromDate}-${form.toDate}.csv`
          );
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingExport(false);
  };

  const handleStopExport = () => {
    setStoppingExport(true);
    stopExport.current = true;
  };

  React.useEffect(() => {
    setStoppingExport(false);
  }, [loadingExport]);

  return (
    <>
      <div className="width-create-Major rounded-lg p-16 my-16 bg-white shadow-sm position-relative">
        <Form className="d-flex flex-wrap">
          <div className="text-18 mb-24 font-weight-bold w-100">
            {t("exportInfo")}
          </div>
          <FormInput
            id="fromDate"
            label={t("fromDate")}
            value={form.fromDate}
            onChange={(e) =>
              setForm((p) => ({ ...p, fromDate: e.target.value }))
            }
            type="date"
            required
            width="50%"
            className="pr-8"
          />
          <FormInput
            id="toDate"
            value={form.toDate}
            onChange={(e) => setForm((p) => ({ ...p, toDate: e.target.value }))}
            type="date"
            label={t("toDate")}
            required
            width="50%"
          />
          <div className="w-100 text-muted">
            <Form.Label>{t("exportUseFilters")}</Form.Label>
          </div>
          {loadingExport ? (
            <div className="d-flex w-100">
              <ProgressBar
                className="w-100 mr-16 my-8"
                animated
                striped
                now={Math.ceil(exportProgress)}
              />
              <Button variant="danger" onClick={handleStopExport}>
                <Loader spinnerClassName="m-1 mx-14" loading={stoppingExport}>
                  {t("Stop")}
                </Loader>
              </Button>
            </div>
          ) : (
            <div
              className="cursor-pointer bg-transparent border-05 rounded border-gray-400 hover-blue no-after flex-center-between pr-8"
              onClick={() => handleExport()}
            >
              <ExportIcon className="m-4" />
              {t("Export")}
            </div>
          )}
        </Form>
        {onClose && (
          <Button
            variant="outline-secondary"
            className="position-absolute"
            style={{ top: 4, right: 4 }}
            onClick={() => onClose()}
          >
            <XIcon />
          </Button>
        )}
      </div>
    </>
  );
};
