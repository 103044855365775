import React from "react";
import api from "../api";
import { useStudents } from "../context/students";
import { useAuth } from "../context/auth";

export const useExportStudents = () => {
  const stopExport = React.useRef(false);
  const [exportProgress, setExportProgress] = React.useState(0);
  const { filters } = useStudents();
  const { user } = useAuth();

  const calculateDaysProgress = (
    dayStart: string,
    dayBetween: string,
    dayEnd: string
  ) => {
    const start = new Date(dayStart);
    const between = new Date(dayBetween);
    const end = new Date(dayEnd);

    const diffBetween = Math.ceil(
      Math.abs(between.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    );

    const diffEnd = Math.ceil(
      Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    );

    const progress = (diffBetween / diffEnd) * 100;
    return progress;
  };

  /** This function suppose to create flat object from a few dimentional object */
  const flattenObject = (ob: any) => {
    var toReturn: any = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == "object" && ob[i] !== null) {
        var flatObject = flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + "." + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };

  const exportStudents = async (form: any) => {
    let filterExpression = [
      `${filters.status
        .map(
          (_: any, i: number) =>
            `contains(student_data.student.status, :status)`
        )
        .join(" OR ")}`,
      `${filters.languages
        .map(
          (_: any, i: number) =>
            `contains(student_data.student.languages, :language${i})`
        )
        .join(" OR ")}`,
      `${filters.mode
        .map(
          (_: any, i: number) =>
            `contains(student_data.student.#mode, :mode${i})`
        )
        .join(" OR ")}`,
      `${filters.degree
        .map(
          (_: any, i: number) =>
            `contains(student_data.student.degree, :degree${i})`
        )
        .join(" OR ")}`,
    ]
      .filter((item) => item !== "")
      .map((item) => `(${item})`)
      .join(" AND ");

    const toDate = new Date(form.toDate);
    toDate.setDate(toDate.getDate() + 1);

    const studentFields = [
      "student_id",
      "#date",
      "student_data.email",
      "student_data.lang",
      "student_data.student.#name",
      "student_data.student.last_name",
      "student_data.student.#mode",
      "student_data.student.degree",
      "student_data.student.languages",
      "student_data.student.newsletter_allowed",
      "student_data.student.sex",
      "student_data.student.is_already_student",
      "student_data.student.r_score",
      "student_data.student.i_score",
      "student_data.student.a_score",
      "student_data.student.s_score",
      "student_data.student.e_score",
      "student_data.student.c_score",
      "student_data.majors_and_fit[0].specialization",
      "student_data.majors_and_fit[0].major_name",
      "student_data.majors_and_fit[0].fit",
      "student_data.majors_and_fit[0].link",
      "student_data.majors_and_fit[1].specialization",
      "student_data.majors_and_fit[1].major_name",
      "student_data.majors_and_fit[1].fit",
      "student_data.majors_and_fit[1].link",
      "student_data.majors_and_fit[2].specialization",
      "student_data.majors_and_fit[2].major_name",
      "student_data.majors_and_fit[2].fit",
      "student_data.majors_and_fit[2].link",
      "student_data.majors_and_fit[3].specialization",
      "student_data.majors_and_fit[3].major_name",
      "student_data.majors_and_fit[3].fit",
      "student_data.majors_and_fit[3].link",
      "student_data.majors_and_fit[4].specialization",
      "student_data.majors_and_fit[4].major_name",
      "student_data.majors_and_fit[4].fit",
      "student_data.majors_and_fit[4].link",
      "student_data.majors_and_fit[5].specialization",
      "student_data.majors_and_fit[5].major_name",
      "student_data.majors_and_fit[5].fit",
      "student_data.majors_and_fit[5].link",
      "student_data.result_page",
    ];

    const params: any = {
      TableName: "studentspeak_new_student",
      ExpressionAttributeNames: {
        "#date": "date",
        "#name": "name",
        "#mode": "mode",
      },
      ExpressionAttributeValues: {
        ...{
          ":dateFrom": form.fromDate,
          ":dateTo": toDate.toISOString().split("T")?.[0],
          ":university_id": user.universityExternalId,
          ":status": filters.status[0],
        },
        ...filters.languages.reduce(
          (acc: object, lang: string, i: number) => ({
            ...acc,
            [`:language${i}`]: lang,
          }),
          {}
        ),
        ...filters.mode.reduce(
          (acc: object, mode: string, i: number) => ({
            ...acc,
            [`:mode${i}`]: mode,
          }),
          {}
        ),
        ...filters.degree.reduce(
          (acc: object, degree: string, i: number) => ({
            ...acc,
            [`:degree${i}`]: degree,
          }),
          {}
        ),
      },
      KeyConditionExpression: `university_id = :university_id AND #date BETWEEN :dateFrom AND :dateTo`,
      ProjectionExpression: studentFields.join(", "),
    };

    if (filterExpression) {
      params.FilterExpression = filterExpression;
    }

    const loadAdditionalResults = async (start?: any) => {
      console.log("Loading more results...");

      const { data } = await api.post(`universities/students/query/`, {
        ...params,
        ExclusiveStartKey: start,
      });

      return data;
    };

    const resultsMap: any = {};
    const duplicates: any[] = [];

    const appendToResults = (s: any) => {
      if (resultsMap[s.student_id]) {
        //duplicate exist
        duplicates.push([resultsMap[s.student_id], s]);
        resultsMap[s.student_id] = {
          ...s,
          date: resultsMap[s.student_id].date,
        }; //we save the first date of duplicate as created date
      } else {
        resultsMap[s.student_id] = s;
      }
    };

    var currentResults = await loadAdditionalResults();

    currentResults.Items.forEach(appendToResults);

    console.log("duplicates", duplicates);

    // results = results.concat(currentResults.Items);

    while (currentResults.LastEvaluatedKey) {
      if (stopExport.current) {
        break;
      }
      setExportProgress(
        calculateDaysProgress(
          form.fromDate,
          currentResults.LastEvaluatedKey.date,
          form.toDate
        )
      );
      currentResults = await loadAdditionalResults(
        currentResults.LastEvaluatedKey
      );

      currentResults.Items.forEach(appendToResults);
    }

    setExportProgress(0);
    const results = Object.values(resultsMap);
    const flattenResults = results.map(flattenObject);

    const replaceRules = {
      ...params.ExpressionAttributeNames,
      "[": ".",
      "]": "",
    };
    /** we want to have list of the fields with regaards of ExpressionAttributeNames*/
    const decodedStudentFields = studentFields.map((field) => {
      let decodedField = field;
      Object.entries(replaceRules).forEach(([key, value]: any) => {
        decodedField = decodedField.replace(key, value);
      });
      return decodedField;
    });

    /** We do it to make sure that records always have a same dimention even if they didn't find corresponding value in the DB*/
    const allFlattenResults = flattenResults.map((flattenStudent: any) => {
      return decodedStudentFields.reduce<Object>((acc: any, field: string) => {
        acc[field] = flattenStudent[field] || "";
        return acc;
      }, {});
    });
    console.log("allFlattenResults", allFlattenResults);
    return allFlattenResults;
  };

  return { exportStudents, exportProgress, stopExport };
};
